import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.location.href = `https://pulsegiftcards.com${window.location.pathname}${window.location.search}`;
  }, []);
  return (
    <>
      {/* <iframe
        allow="clipboard-read; clipboard-write"
        src="https://gamekeytoplist.com/flow/11?source=gamdom"
        width="350px"
        height="500px"
        frameborder="none"
      ></iframe> */}
    </>
  );
}

export default App;
